<template>
	
	<div class="mobile-block-advanced block">

		<header class="block-head">
			<slot name="block-head"></slot>
		</header>

		<div class="block-body">
			<slot name="block-body"></slot>
		</div>
	</div>

</template>

<script>

	export default {
		
		name	: 'mobileBlockAdvanced',

		props	: [ 'player', 'compare' ],

		data	: function() { return {
		}},

		methods	: {
		},

		watch	: {
		},

		computed	: {
		},

		mounted	: function() {
		},

	}

</script>

<style lang="scss" scoped>

	@import '@/assets/scss/_base';


	/** ---------------------------------------*\
	*---------------------------------------- **/

	.mobile-block-advanced {

		@include flex_display;
		flex: 100%;
		max-width: 100%;
		border-bottom: 2px solid lighten( #000, 75% );
		text-transform: uppercase;

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	.block .block-head,
	.block .block-body {

		@include flex_display;
		justify-content: space-between;
		width: 100%;

		.score {

			@include flex_display;
			justify-content: center;
			width: 40px;
			height: 40px;
			font-size: 1em;

			.ttfl-score {
				@include flex_display;
				justify-content: center;
				font-size: 1em;
			}

		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	.block .block-head {

		height: 40px;
		background-color: lighten( #000, 90% );
		font-size: 0.8em;

		& > div,
		& > div > div {
			@include flex_display;
			height: 100%;
			background: transparent;
		}

		& > div > div {

			padding: 5px 10px;

			&.th-title {
				background-color: #000;
				color: #FFF;
				font-weight: bold;
			}

		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	.block .block-body {

		align-items: stretch;
		
		& > div {
			@include flex_display;
			flex-flow: row nowrap;
		}

		.team-logo {
			@include flex_display;
			justify-content: center;
			width: 40px;
			height: 40px;
		}

		.score {
			font-size: 0.875em;
		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	.block.heat-map {

		.block-head .score {
			font-size: 0.8em;
		}

		span.ttfl-score_bg {
			background-color: $orange;
		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	.block.compare {

		.block-head .vselect,
		.block-head button {
			height: 30px;
		}

		.block-head button {

			border-right: 0;

			&:hover,
			&:focus {
				background-color: transparent;
				color: #000;
			}

			&.without {
				background-color: #000;
				color: #FFF;
			}

		}

		.block-body > div {
			flex-flow: row wrap;
		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	.block.compare .block-head .vselect::v-deep {

		@include iphone5() {
			max-width: 150px;
		}
		
		.vselect-selector {

			padding: 5px 15px;

			@include mobile() {
				padding: 5px 10px;
			}

			.selected {
				white-space: nowrap;
			}

		}

	}

</style>